import React from 'react'
import image_1 from '../assets/image_1.png'

const Product = ({ product }) => {
  return (
    <div className='product'>
        <div className='product-image'>
            <img src={image_1} alt=''/>
        </div>
        <div className='product-details'>
            <p><strong>{product.name}</strong></p>
            <p>{product.farmer}</p>
            <p>{'KES: '+product.price}</p>
        </div>
    </div>
  )
}

export default Product
