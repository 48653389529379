import React from 'react'
import Product from './Product'

const Products = () => {
  const products = [
    {id: 1, name : 'Product One', farmer : 'Farmer One', price: 100},
    {id: 2, name : 'Product Two', farmer : 'Farmer One', price: 100},
    {id: 3, name : 'Product Three', farmer : 'Farmer One', price: 100},
    {id: 4, name : 'Product Four', farmer : 'Farmer One', price: 100},
    {id: 5, name : 'Product Five', farmer : 'Farmer One', price: 100},
    {id: 6, name : 'Product Six', farmer : 'Farmer One', price: 100},
    {id: 7, name : 'Product Seven', farmer : 'Farmer One', price: 100},
    {id: 8, name : 'Product Eight', farmer : 'Farmer One', price: 100},
    {id: 9, name : 'Product Nine', farmer : 'Farmer One', price: 100},
    {id: 10, name : 'Product Ten', farmer : 'Farmer One', price: 100}
  ];
  return (
    <div className='products'>
      {products.map((product) => <Product key={product.id} product={product}/>)}
    </div>
  )
}

export default Products
