import Products from './Products';
import Categories from './Categories';
import HeroCarousel from './HeroCarousel';

const Home = () => {

  return (
    <div className='home'>
        <HeroCarousel />

      <div className='products-categories-card'>
        <Categories />
        <Products />
      </div>
    </div>
  )
}

export default Home
