import image_1 from '../assets/image_1.png'
import image_2 from '../assets/image_2.jpg'
import image_3 from '../assets/image_3.jpg'

import { useState, useEffect } from 'react';

function HeroCarousel() {
    const [heroCount, setHeroCount] = useState(0);

    useEffect(() => {
      setInterval(() => {
        setHeroCount((count) =>{ return count===2 ? 0 : count+1 })
      }, 3000)
    }, []);

    function displayPhoto(heroCount) {
        if(heroCount === 0){
            return <img src={image_1} className='background' alt='' />
        }
        else if(heroCount === 1){
            return <img src={image_2} className='background' alt='' />
        }
          else if(heroCount === 2){
            return <img src={image_3} className='background' alt='' />
        }
    }

  return (
    <div className='hero-carousel'>
        {displayPhoto(heroCount)}

        <div className="hero">
            <div className='hero-dot-play'>
                <ul className='hero-dots'>
                    <li onClick={()=>setHeroCount(0)} className={heroCount===0 ? 'hero-dot green' : 'hero-dot'}></li>
                    <li onClick={()=>setHeroCount(1)} className={heroCount===1 ? 'hero-dot green' : 'hero-dot'}></li>
                    <li onClick={()=>setHeroCount(2)} className={heroCount===2 ? 'hero-dot green' : 'hero-dot'}></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default HeroCarousel
