import './App.css';
import Home from './components/Home';
import NavBar from './components/NavBar';
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path={'/home'} element={<Home />} />
      </Routes>
      <div className='footer'>
        <a href=''>Privacy Policy</a>
        <a href=''>Terms & Conditions</a>
      </div>
    </div>
  );
}

export default App;
