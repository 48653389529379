import React from 'react'

function Categories() {
  return (
    <div className='categories'>
        <p><strong>Categories</strong></p>
        <p>Category1</p>
        <p>Category2</p>
        <p>Category3</p>
        <p>Category4</p>
    </div>
  )
}

export default Categories
